* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Ember";
  font-weight: 300;

  background-attachment: fixed;
  background-image: url("./static/content.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Th.woff2") format("woff2");
  font-weight: 100;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_ThIt.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Lt.woff2") format("woff2");
  font-weight: 200;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_LtIt.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Rg.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_RgIt.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_SBd.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_SBdIt.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Bd.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_BdIt.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}